<template>
  <section class="register-2">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div
          class="content-wrapper d-flex align-items-stretch auth auth-img-bg"
        >
          <div class="row flex-grow">
            <div
              class="col-lg-6 d-flex align-items-center justify-content-center"
            >
              <div class="auth-form-transparent text-left p-3">
                <div class="brand-logo">
                  <img src="@/assets/images/piattino-logo.png" alt="logo" />
                </div>
                <h4>Redefinir Senha</h4>
                <h6 class="font-weight-light">
                  Por favor, informe a nova senha.
                </h6>
                <b-alert :show="message != ''" variant="danger">{{
                  message
                }}</b-alert>
                <form class="pt-3" @submit.stop.prevent="resetPassword">
                  <b-form-group label="Senha" label-for="passwordInput">
                    <b-input-group>
                      <b-input-group-prepend class="bg-transparent">
                        <b-input-group-text
                          tag="span"
                          class="bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-lock-outline text-primary"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="passwordInput"
                        type="password"
                        placeholder="Senha"
                        v-model="$v.user.password.$model"
                        :state="
                          $v.user.password.$dirty
                            ? !$v.user.password.$error
                            : null
                        "
                        aria-describedby="password-live-feedback"
                        class="border-left-0"
                        size="lg"
                      ></b-form-input>
                      <b-form-invalid-feedback id="password-live-feedback">
                        <template v-if="!$v.user.password.required"
                          >Preenchimento obrigatório</template
                        >
                        <template v-if="!$v.user.password.minLength"
                          >Senha deve ter
                          {{ $v.user.password.$params.minLength.min }} ou mais
                          caracteres</template
                        >
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    label="Confirmar Senha"
                    label-for="passwordConfirmationInput"
                  >
                    <b-input-group>
                      <b-input-group-prepend class="bg-transparent">
                        <b-input-group-text
                          tag="span"
                          class="bg-transparent border-right-0"
                        >
                          <i class="mdi mdi-lock-outline text-primary"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="passwordConfirmationInput"
                        type="password"
                        placeholder="Confirmar Senha"
                        v-model="$v.user.password_confirmation.$model"
                        :state="
                          $v.user.password_confirmation.$dirty
                            ? !$v.user.password_confirmation.$error
                            : null
                        "
                        aria-describedby="password_confirmation-live-feedback"
                        class="border-left-0"
                        size="lg"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="password_confirmation-live-feedback"
                      >
                        <template
                          v-if="!$v.user.password_confirmation.sameAsPassword"
                          >Senhas não conferem</template
                        >
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                  <div
                    class="
                      my-2
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                  ></div>
                  <div class="my-3">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="btn-block font-weight-medium auth-form-btn"
                      size="lg"
                      :disabled="loading"
                    >
                      <template v-if="loading">
                        <b-spinner small type="grow"></b-spinner>
                        Processando...
                      </template>
                      <template v-else> REDEFINIR SENHA </template>
                    </b-button>
                  </div>
                  <div class="text-center mt-4 font-weight-light">
                    <router-link :to="{ name: 'Login' }" class="text-primary"
                      >Voltar para login</router-link
                    >
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 login-half-bg d-flex flex-row">
              <p
                class="
                  text-white
                  font-weight-medium
                  text-center
                  flex-grow
                  align-self-end
                "
              >
                Copyright &copy;
                <a href="https://piattino.digital/" target="_blank"
                  >Piattino
                </a>
                2021 Todos os direitos reservados.
              </p>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import User from "@/models/user";
import AuthService from "../../services/auth.service";

export default {
  name: "PasswordReset",
  data() {
    return {
      user: new User(),
      loading: false,
      message: "",
    };
  },
  created() {
    if (this.$route.query.email && this.$route.query.token) {
      this.user.email = this.$route.query.email;
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    resetPassword() {
      this.message = "";
      this.loading = true;
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        this.loading = false;
        return;
      }

      const promise = AuthService.resetPassword(
        this.user,
        this.$route.query.token
      );
      return promise
        .then((response) => {
          this.$bvModal
            .msgBoxOk(response.data.message, {
              title: "Sucesso!",
              size: "md",
              buttonSize: "md",
              okVariant: "success",
              okTitle: "Fazer login",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
              headerBgVariant: "primary",
              headerTextVariant: "light",
              footerBgVariant: "dark"
            })
            .then(() => {
              this.$router.push({ name: "Login" });
            });
        })
        .catch((error) => {
          if (error.response) {
            this.message = error.response.data.message;
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  validations: {
    user: {
      password: {
        required,
        minLength: minLength(6),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
};
</script>
